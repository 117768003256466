import * as React from "react";

import { SecondaryPageTitle, SeriousTalk } from "../components/shared/secondary-page-elements";

import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import { H5 } from "../components/shared/headings";
import Layout from "../components/shared/layout";
import Navbar from "../components/navbar";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";

const SecurityPolicyPage = () => {
  return (
    <Layout>
        <Seo 
          title={"Security Policy | Copilot"}
        />
        <DownloadModal/>
        <Navbar/>
        <Section>
            <Grid>
                <SecondaryPageTitle>Security policy</SecondaryPageTitle>
                <SeriousTalk>
                  <H5>Rules</H5>
                  <ul>
                    <li>Do not impact other users with your testing, this includes changing or accessing other users' data. If you are attempting to find an authorization bypass, you must use accounts you own.</li>
                    <li>Stop <strong>immediately</strong> if you believe you have affected the availability of our services. Don't worry about demonstrating the full impact of your vulnerability, Copilot's security team will be able to assess the impact.</li>
                    <li>Never attempt non-technical attacks such as social engineering, phishing, or physical attacks against our employees, users, or infrastructure.</li>
                    <li>When in doubt, contact us at <a target="_blank" rel="noopener noreferrer" href="mailto:security@copilot.money">security@copilot.money</a>.</li>
                  </ul>
                  <H5>Reporting</H5>
                  <ul>
                    <li>Submissions must include written instructions for reproducing the vulnerability.</li>
                    <li>When reporting vulnerabilities you <strong>must</strong> keep all information confidential. Do not post information to video-sharing or pastebin sites. Videos and images can be sent directly to us.</li>
                    <li>For vulnerabilities involving personally identifiable information, please explain the kind of PII you believe is exposed and limit the amount of PII data included in your submissions. For textual information and screenshots, please only include redacted data in your submission.</li>
                    <li><strong>Do not publicly disclose</strong> your submission until Copilot has evaluated the impact.</li>
                  </ul>
                  <H5>Targets</H5>
                  <ul>
                    <li>Copilot's Mobile Apps (<a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/copilot-the-smart-money-app/id1447330651">iOS</a>)</li>
                    <li>
                      All subdomains of <code>copilot.money</code>, except for:
                      <ul>
                        <li><code>help.copilot.money</code></li>
                      </ul>
                    </li>
                    <li>All subdomains of <code>joincopilot.com</code></li>
                    <li><code>us-central1-copilot-production-22904.cloudfunctions.net</code></li>
                  </ul>
                </SeriousTalk>
            </Grid>
        </Section>
        <Footer/>
    </Layout>
  )
}

export default SecurityPolicyPage
